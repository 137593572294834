<template>
  <div>
    <ToolbarOxy></ToolbarOxy>
    <ContactOxy></ContactOxy>
    <FooterOxy></FooterOxy>
  </div>
</template>

<script>
import ToolbarOxy from '@/components/ui/landing/toolbar/ToolbarOxy.vue'
import ContactOxy from '@/components/ui/landing/contact/ContactOxy.vue'
import FooterOxy from '@/components/ui/landing/footer/FooterOxy.vue'

export default {
  components: {
    ToolbarOxy,
    ContactOxy,
    FooterOxy
  }
}
</script>
